<style scoped>
</style>

<template>
  <!-- <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange"> -->
  <AppPage>
    <template v-slot:header>
      <Row type="flex" justify="space-between" :wrap="false" align="middle" style="margin-bottom: 10px;">
        <i-col style="flex: 1; width: 0;overflow-x: auto;white-space: nowrap;">
          <Row align="middle">
            <i-col>
              <fm-title v-if="groupData" :title-text="groupData.name"></fm-title>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" style="margin-left: 10px;" icon="md-refresh">刷新</Button>
          <Button v-if="status.mode" type="primary" @click="status.mode = false;loadData()" style="margin-left: 10px;">查看明细统计</Button>
          <Button type="primary" v-else @click="status.mode = true;loadData()" style="margin-left: 10px;">返回</Button>
          <Button v-if="$route.query.back" @click="$router.back()" style="margin-left: 10px;" icon="ios-arrow-back">返回</Button>
          <Button @click="status.batchForm = true" v-if="useUpdate" style="margin-left: 10px;" :loading="loading.load" icon="md-add" type="primary">基于生产标准新增</Button>
          <!-- <Button v-if="useUpdate" @click="itemClick(null)" style="margin-left: 10px;" :loading="loading.load" icon="md-add" type="primary">新增</Button> -->
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <div :style="{ height: contentHeight + 'px' }" style="display: flex;position: relative;overflow: hidden;">
        <div style="flex: 1; width: 0">
          <Table row-key="id" :load-data="handleLoadData" :height="contentHeight" :data="dataList" :columns="columns" :loading="loading.load">
            <template v-slot:planVolume="{ row }">
              <InputNumber
                v-if="useUpdate"
                :min="1"
                :active-change="false"
                @on-change="planVolume => submit(row.id, { planVolume: planVolume || 0 })"
                :value="row.planVolume"
                :formatter="value => `${value}${row.goodsUnit || ''}`"
                :parser="value => value.replace(row.goodsUnit, '')"
              />
              <span v-else>{{ row.planVolume }}{{ row.goodsUnit }}</span>
            </template>
            <template v-slot:outVolume="{ row }">
              {{ row.outVolume !== null ? (row.outVolume + ' ' + row.goodsUnit) : '-' }}
            </template>
            <template v-slot:action="{ row }">
              <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
                <Button :loading="loading.del" type="warning" size="small">删除</Button>
              </Poptip>
            </template>
          </Table>
        </div>
        <GroupSelect :style="{pointerEvents: loading.load ? 'none' : 'auto'}" v-if="useUpdate" @select="onSelectGoods" style="width: 225px;height: 100%;margin-left: 10px;border-left: 1px solid #EEE;" />
      </div>
    </template>
    <template v-slot:footer v-if="useUpdate">
      <Drawer placement="right" title="基于生产标准新增出库明细" :mask-closable="false" closable transfer draggable :width="30" v-model="status.batchForm">
        <DrawerAction :loading="loading.submit" @confirm="bacthSubmit" @cancel="status.batchForm = false">
          <OutorderWitchStandardForm ref="batchform" :data="formData" />
        </DrawerAction>
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import DrawerAction from '../../components/drawer/action.vue'
import request from '../../api/index'
import GroupSelect from '../../components/goods/base/group/select/goods.vue'
import OutorderWitchStandardForm from '../../components/goods/produce/detail/form/outorderWithStandard.vue'

export default {
  components: { AppPage, GroupSelect, DrawerAction,OutorderWitchStandardForm },
  props: {
    outOrderGroupId: { type: Number, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {},
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false,
        batchForm: false,
        mode: true
      },
      formData: null,
      groupData: null
    }
  },
  computed: {
    useUpdate () {
      const status = this.groupData ? this.groupData.exeStatus : null
      return [0].includes(status) && this.status.mode && this.$authFunsProxy.manage
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    currentOutOrderGroupId () {
      let outOrderGroupId = this.outOrderGroupId || this.$route.query.outOrderGroupId
      return outOrderGroupId && !isNaN(outOrderGroupId) ? Number(outOrderGroupId) : null
    },
    columns () {
      const columns = [
        {
          title: '物品', key: 'goodsId', width: 180, tree: true, render (h, { row }) {
            if (row.goodsGroupName) {
              return h('span', [
                h('span', row.goodsGroupName),
                h('span', {
                  style: { color: '#005AFF' }
                }, '（物品组）')
              ])
            } else {
              return h('span', row.goodsName)
            }
          }
        },
        { title: '规格型号', key: 'goodsGgxh', minWidth: 100 },
        { title: '计划出库量', key: 'planVolume', slot: 'planVolume', minWidth: 130 },
        { title: '出库量', key: 'outVolume', slot: 'outVolume', minWidth: 130 }
      ]
      if (this.useUpdate) {
        columns.push({ title: '操作', key: 'action', slot: 'action', width: 80 })
      }
      return columns
    }
  },
  watch: {
    currentOutOrderGroupId () {
      if (this.routerName === this.$route.name) {
        this.loadGroupData()
        this.refresh()
      }
    }
  },
  methods: {
    async loadGroupData () {
      const res = await request.get('/goods_stock_out_order/group', { id: this.currentOutOrderGroupId })
      this.groupData = res.length ? res[0] : null
    },
    async onSelectGoods ({ type, value }) {
      try {
        if (type === 2) {
          let has = this.dataList.find(v => v.goodsId === value.id)
          if (has) {
            return this.submit(has.id, { planVolume: has.planVolume + 1 })
          }
        }

        let params = {
          goodsStockOutOrderGroupId: this.currentOutOrderGroupId,
          planVolume: 1,
          remark: null,
          accordingType: 'goods_produce'
        }

        if (type === 1) {
          params.goodsGroupId = value.id
          params.detailMap = value.detailMap
          params.planVolume = value.num
        } else {
          params.goodsId = value.id
        }

        await request.post('/goods_stock_out_order', params)
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
    },
    itemClick (item) {
      this.formData = item
      this.status.form = true
    },
    async itemDel (item) {
      this.loading.del = true
      try {
        await request.delete('/goods_stock_out_order/' + item.id)
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.del = false
    },
    async submit (id, data) {
      try {
        await request.put('/goods_stock_out_order/' + id, data)
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
    },
    async bacthSubmit () {
      try {
        const data = await this.$refs.batchform.getFormData()
        if (data === false) {
          return this.$Message.warning('请完整填写表单')
        }
        if (data === null) {
          return
        }

        let params = {
          goodsStockOutOrderGroupId: this.currentOutOrderGroupId,
          accordingType:'goods_produce',
          accordingDataId: -1,
          ...data
        }

        this.loading.submit = true
        await request.post('/goods_stock_out_order/by_produce_standard', params)
        this.$Message.success('操作成功')
        this.status.batchForm = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    refresh () {
      this.pager.page = 1
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async handleLoadData (item, callback) {
      try {
        const res = await request.get('/goods_stock_out_order', {
          pid: item.id
        })
        callback(res)
      } catch (e) {
        callback([])
        this.$Modal.error({
          title: '获取失败',
          content: e.message
        })
      }
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.currentOutOrderGroupId) {
        params.goodsStockOutOrderGroupId = this.currentOutOrderGroupId
      }

      let url = null
      if (this.status.mode) {
        url = '/goods_stock_out_order'
        params.noChild = 1
      } else {
        url = '/goods_stock_out_order/count'
        params.noGroup = 1
      }

      this.loading.load = true
      try {
        const res = await request.get(url, Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach((item, id) => {
          // item.goodsStatusData = this.scmGoodsStatusList.find(v => v.value === item.goodsStatus)
          if (item.goodsGroupId) {
            item.goodsUseTypeText = null
            item._loading = false
            item.children = []
          } else {
            item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
          }
          if (!item.id) {
            item.id = id
          }
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
      this.loadGroupData()
    }
  },
  mounted () {
    this.onRowsChange(999)
  }
}
</script>
