<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="生产标准" prop="goodsProduceStandardId">
      <Select clearable v-model="formData.goodsProduceStandardId" placeholder="请选择生产标准">
        <Option v-for="item in goodsProduceStandardList" :key="item.id" :value="item.id">{{ item.name }}</Option>
      </Select>
    </FormItem>
    <FormItem label="次数" prop="volume">
      <InputNumber controls-outside v-model="formData.volume" />
    </FormItem>
  </Form>
</template>

<script>
export function getDefault () {
  return {
    id: null,
    goodsProduceStandardId: null,
    volume: 1,
    remark: null,
  }
}

const rules = Object.freeze({
  goodsProduceStandardId: [
    { required: true, message: '请选择生产标准' }
  ],
  volume: [
    { required: true, message: '请输入次数' }
  ]
})

export default {
  props: {
    data: { type: Object, default: null }
  },
  computed: {
    goodsProduceStandardList () {
      return this.$store.getters.goodsProduceStandardList
    }
  },
  data () {
    return {
      formData: this.data || getDefault(),
      rules
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault()
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  },
  mounted () {
    this.$store.dispatch('loadGoodsProduceStandard')
  }
}
</script>
